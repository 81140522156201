import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, CardList } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="শিক্ষাপোকরণ" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  শিক্ষাপোকরণ
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <CardList books={[{
        title: 'পাঠ্যক্রম',
        link: '/study-materials/syllabus'
      }, {
        title: 'আরবী',
        link: '/study-materials/arabic'
      }, {
        title: 'বাংলা',
        link: '/study-materials/bengali'
      }, {
        title: 'ইংরেজী',
        link: '/study-materials/english'
      }, {
        title: 'গণিত',
        link: '/study-materials/mathematics'
      }, {
        title: 'কুর্‌আন ও হাদীস',
        link: '/study-materials/quran-and-hadith'
      }, {
        title: 'হাদীস শিখি ইউশার সাথে',
        link: '/study-materials/learning-hadith-with-yusha'
      }]} mdxType="CardList" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      